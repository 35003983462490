import { FC, useEffect, useState } from "react";
import Footer, { NewFooter } from "@components/Footer";
import Script from "next/script";
import { PageView } from "@framework/types/site";
import dfp from "@framework/lib/dfp";
import AdsSlot from "@components/AdsSlot/AdsSlot";
import { useRouter } from "next/router";
import { ButtonFlotant } from "@components/ui";
import NextHead from 'next/head';
import { Navbar } from "..";
import AdsSlotTop from "@components/AdsSlot/AdsSlotTop";
import classNames from 'classnames';
import { isInTimeRange } from "utils/getPeruTime";


interface Props{
  pageProps: {
    pageView: PageView
    menus: any
    ads: any
    view: any
    seo?: any
    clever?: any
    ticker: Array<any>
  }
}

declare let window: CustomWindow
declare const inView: any
declare const stpd: any
interface CustomWindow extends Window {
  w2g: {
      single: (domain: string, slot: string, parent: HTMLElement | null) => void;
  };
  w2gLoaded: number;
  googletag: any;
  stpd: any;
  inView: any
}

const Layout: FC<Props> = ( { children, pageProps: { menus, ads, view, seo, clever , pageView = PageView.HOME, ticker} } ) => {
  const [device, setDevice] = useState('')
  const [showAdsTop, setShowAdsTop] = useState(false)
  const router = useRouter()

  useEffect(() => {
    const showAdsSlotTop = isInTimeRange(9, 18); // 9 AM to 6 PM
    showAdsSlotTop ? setShowAdsTop(true) : setShowAdsTop(false)
    const script_inview = document.createElement("script");
    script_inview.src = "https://stpd.cloud/assets/libraries/inview.min.js";
    script_inview.id = "script_inview";
    script_inview.onload = () => {
      inView.offset(-300);
      const script_gpt = document.createElement("script");
      script_gpt.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
      script_gpt.id = "script_gpt";
      const scriptContainer = document.getElementById("script-container");
      if(document.getElementById("script_gpt")){
        const elementsToRemove = document.querySelectorAll('#script_gpt');
        elementsToRemove.forEach((element) => {
          element.remove();
        });
        scriptContainer?.appendChild(script_gpt);
      }else{
        scriptContainer?.appendChild(script_gpt);
      }
      if(ads){
        window.googletag = window.googletag || {}
        window.googletag.cmd = window.googletag.cmd || [];
        dfp.defineSlot(ads.skin?.key, ads.skin?.size, ads.skin?.div, 'skin');
        dfp.defineSlot(ads.top0?.key, ads.top0?.size, ads.top0?.div, 'top0');
        if(window.innerWidth > 1260){
          dfp.defineSlot(
            '/147246189,429892472/cuantoestaeldolar.pe_144x600_skyscraper_left_desktop', 
            '[[120,600],[144,600],[120,240]]', 
            'cuantoestaeldolar_pe_144x600_skyscraper_left_desktop',
            '144x600_skyscraper_left_desktop')
          dfp.defineSlot(
            '/147246189,429892472/cuantoestaeldolar.pe_144x600_skyscraper_right_desktop', 
            '[[120,600],[144,600],[120,240]]',
            'cuantoestaeldolar_pe_144x600_skyscraper_right_desktop',
            '144x600_skyscraper_right_desktop'
          )
          dfp.defineSlot(
            '/147246189,429892472/cuantoestaeldolar.pe_970x90_banner_top_desktop',
            '[[970,90],[728,90],[970,50],[960,90],[950,90]]',
            'cuantoestaeldolar_pe_970x90_banner_top_responsive',
            '970x90_banner_top_desktop'
          )
          dfp.defineSlot(
            '/147246189,429892472/cuantoestaeldolar.pe_970x250_banner_desktop_1',
            '[[970,250],[970,90],[728,90],[970,200],[970,120],[950,90],[728,100],[728,250]]',
            'cuantoestaeldolar_pe_970x250_banner_responsive_1',
            '970x250_banner_desktop_1')
          dfp.defineSlot(
            '/147246189,429892472/cuantoestaeldolar.pe_1000x100_anchor_desktop',
            '[[1000,100],[970,90],[728,90],[990,90],[970,50],[960,90],[950,90],[980,90]]',
            'cuantoestaeldolar_pe_1000x100_anchor_responsive',
            '1000x100_anchor_desktop'
          )
          dfp.defineSlot(
            '/147246189,429892472/cuantoestaeldolar.pe_970x250_banner_lazy_repeatable_desktop',
            '[[970,250],[970,90],[728,90],[970,200],[970,120],[950,90],[728,100],[728,250]]',
            'cuantoestaeldolar_pe_970x250_banner_lazy_repeatable_responsive',
            '970x250_banner_lazy_repeatable_desktop',
            inView
          )
        }else if (window.innerWidth > 1000) {
          dfp.defineSlot(
            '/147246189,429892472/cuantoestaeldolar.pe_970x90_banner_top_desktop',
            '[[970,90],[728,90],[970,50],[960,90],[950,90]]',
            'cuantoestaeldolar_pe_970x90_banner_top_responsive',
            '970x90_banner_top_desktop'
          )
          dfp.defineSlot(
            '/147246189,429892472/cuantoestaeldolar.pe_970x250_banner_desktop_1',
            '[[970,250],[970,90],[728,90],[970,200],[970,120],[950,90],[728,100],[728,250]]',
            'cuantoestaeldolar_pe_970x250_banner_responsive_1',
            '970x250_banner_desktop_1')
          dfp.defineSlot(
            '/147246189,429892472/cuantoestaeldolar.pe_1000x100_anchor_desktop',
            '[[1000,100],[970,90],[728,90],[990,90],[970,50],[960,90],[950,90],[980,90]]',
            'cuantoestaeldolar_pe_1000x100_anchor_responsive',
            '1000x100_anchor_desktop'
          )
          dfp.defineSlot(
            '/147246189,429892472/cuantoestaeldolar.pe_970x250_banner_lazy_repeatable_desktop',
            '[[970,250],[970,90],[728,90],[970,200],[970,120],[950,90],[728,100],[728,250]]',
            'cuantoestaeldolar_pe_970x250_banner_lazy_repeatable_responsive',
            '970x250_banner_lazy_repeatable_desktop',
            inView
          )
        }else{
          dfp.defineSlot(
            '/147246189,429892472/cuantoestaeldolar.pe_336x336_banner_top_mobile',
            '[[300,250],[336,336],[336,320],[320,320],[300,300],[336,280],[320,250],[320,336]]',
            'cuantoestaeldolar_pe_970x90_banner_top_responsive',
            '970x90_banner_top_desktop'
          )
          dfp.defineSlot(
            '/147246189,429892472/cuantoestaeldolar.pe_336x336_banner_mobile_1',
            '[[300,250],[336,336],[336,320],[320,320],[300,300],[336,280],[320,250],[320,336]]',
            'cuantoestaeldolar_pe_970x250_banner_responsive_1',
            '970x250_banner_desktop_1')
          dfp.defineSlot(
            '/147246189,429892472/cuantoestaeldolar.pe_320x100_anchor_mobile',
            '[[320,100],[320,50],[300,100],[300,50]]',
            'cuantoestaeldolar_pe_1000x100_anchor_responsive',
            '1000x100_anchor_desktop')
          dfp.defineSlot(
            '/147246189,429892472/cuantoestaeldolar.pe_320x100_anchor_top_mobile',
            '[[320,100],[320,50],[300,100],[300,50]]',
            'cuantoestaeldolar_pe_320x100_anchor_top_mobile',
            '320x100_anchor_top_mobile')
          dfp.defineSlot(
            '/147246189,429892472/cuantoestaeldolar.pe_336x336_banner_lazy_repeatable_mobile',
            '[[300,250],[336,336],[336,320],[320,320],[300,300],[336,280],[320,250],[320,336]]',
            'cuantoestaeldolar_pe_970x250_banner_lazy_repeatable_responsive',
            '970x250_banner_lazy_repeatable_desktop',
            inView
            )
        }
        // dfp.defineOutOfPageSlot('/147246189,429892472/cuantoestaeldolar.pe_interstitial')
      }
    };
    if (document.getElementById("script_inview")) {
      const elementsToRemove = document.querySelectorAll('#script_inview');
      elementsToRemove.forEach((element) => {
        element.remove();
      });
    
      // Obtén la referencia del primer hijo del body
      const firstChild = document.body.firstChild;
    
      // Inserta el elemento script_inview antes del primer hijo del body
      document.body.insertBefore(script_inview, firstChild);
    } else {
      // Obtén la referencia del primer hijo del body
      const firstChild = document.body.firstChild;
    
      // Inserta el elemento script_inview antes del primer hijo del body
      document.body.insertBefore(script_inview, firstChild);
    }
    const stdp = document.createElement("script");
    stdp.src = "https://stpd.cloud/saas/4402";
    stdp.id = "script_stdp";
    const scriptContainer = document.getElementById("script-container");
    if(document.getElementById("script_stdp")){
      const elementsToRemove = document.querySelectorAll('#script_stdp');
      elementsToRemove.forEach((element) => {
        element.remove();
      });
      scriptContainer?.appendChild(stdp);
    }else{
      scriptContainer?.appendChild(stdp);
    }
    const updateWithDisplay = () => {
      setDevice(window.innerWidth > 768 ? 'desktop' : 'mobile');
    }
    window.addEventListener('resize', updateWithDisplay);
    setDevice(window.innerWidth > 768 ? 'desktop' : 'mobile');
    window.stpd = window.stpd || {que: []};
    stpd.que.push((function() {
    stpd.refreshAdUnit('cuantoestaeldolar_pe_970x250_banner_responsive_1');
    stpd.refreshAdUnit('cuantoestaeldolar_pe_1000x100_anchor_responsive');
    stpd.refreshAdUnit('cuantoestaeldolar_pe_970x250_banner_lazy_repeatable_responsive');
    stpd.refreshAdUnit('cuantoestaeldolar_pe_970x90_banner_top_responsive');
    stpd.refreshAdUnit('cuantoestaeldolar_pe_144x600_skyscraper_left_desktop');
    stpd.refreshAdUnit('cuantoestaeldolar_pe_144x600_skyscraper_right_desktop');
    stpd.refreshAdUnit('cuantoestaeldolar_pe_320x100_anchor_top_mobile');
  }));
    const scriptV = document.createElement("script");
    scriptV.src = "https://video.onnetwork.tv/widget/widget_scrolllist.php?widget=1239";
    scriptV.id = "script_video";
    const scriptVi = document.getElementById("video_ad");
    if(document.getElementById("script_video")){
      const elementsToRemove = document.querySelectorAll('#script_video');
      elementsToRemove.forEach((element) => {
        element.remove();
      });
      scriptVi?.appendChild(scriptV);
    }else{
      scriptVi?.appendChild(scriptV);
    }
    
  return () => {
      window.removeEventListener('resize', updateWithDisplay);
    };
  }, [ads, pageView, router.pathname, view, ]);

  useEffect(() => {
    if(document.getElementById("cuantoestaeldolar_pe_320x100_anchor_top_mobile")){
      const elementchildren = document.getElementById("google_ads_iframe_/147246189,429892472/cuantoestaeldolar.pe_320x100_anchor_top_mobile_1__container__")
      if(elementchildren){
        elementchildren.style.setProperty('height', 'auto', 'important');
      }
    }
  }, [router.pathname, view,]);

  useEffect(() => {
    const checkAdSpace = setInterval(() => {
      const adDiv = document.querySelector('.clever-core-ads');
      if (adDiv) {
        console.log('Ad space found:', adDiv);
        clearInterval(checkAdSpace);
      }
    }, 500);

    return () => clearInterval(checkAdSpace);
  }, []);

  useEffect(() => {
    const loadCleverScript = () => {
      const script = document.createElement('script');
      script.id = 'clever-core';
      script.src = 'https://scripts.cleverwebserver.com/79ac918c34144c1cffd9caba41969fba.js';
      script.async = true;
      script.type = 'text/javascript';
      script.setAttribute('data-cfasync', 'false');
      script.setAttribute('data-target', window.name || '');
      script.setAttribute('data-callback', 'put-your-callback-function-here');
      script.setAttribute('data-callback-url-click', 'put-your-click-macro-here');
      script.setAttribute('data-callback-url-view', 'put-your-view-macro-here');

      script.onload = () => {
        console.log('CleverCore script loaded successfully.');
      };

      script.onerror = (err) => {
        console.error('Error loading CleverCore script:', err);
      };

      document.body.appendChild(script);
    };

    const observer = new MutationObserver((mutations) => {
      const adContainer = document.querySelector('.clever-core-ads');
      if (adContainer) {
        loadCleverScript();
        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      {ads && pageView !== PageView.LANDING  &&(
        <AdsSlot className="fixed top-0 w-[1600px] h-[1000px] ml-[-800px] left-[50%]" variant="skin" div="div-gpt-ad-1656710058909-0" />
      )}
      {ads && pageView !== PageView.LANDING  &&(
        <AdsSlot className="" variant="top0" div="div-gpt-ad-1487022995851-0"/>
        )}
      {ads && pageView === PageView.LANDING && (
        <div id='cuantoestaeldolar_pe_970x90_banner_top_responsive' className={`leaderboard-height text-center`} />
      )}
      {ads && pageView === PageView.LANDING && (
        <div id='cuantoestaeldolar_pe_144x600_skyscraper_left_desktop' className="fixed top-[90px] left-0" />
      )}
      {ads && pageView === PageView.LANDING && (
        <div id='cuantoestaeldolar_pe_144x600_skyscraper_right_desktop'  className="fixed top-[90px] right-0" />
      )}
      {/* {ads && pageView === PageView.HOME && (
          <AdsSlot div='cuantoestaeldolar_pe_1000x100_anchor_responsive' variant="anchor" className="text-center" />
        )
      } */}
      <main className="max-w-screen md:mx-auto z-10 relative ">
        <NextHead>
        {
          seo?(
            <>
              <title>{seo?.title}</title>
              <meta name="description" content={seo?.description}/>
              <meta name="keywords" content={seo?.keywords} />
              <meta property="og:title" content={seo?.title} />
              <meta property="og:description" content={seo?.description} />
              <meta property="og:url" content="https://cuantoestaeldolar.pe/" />
              <meta property="og:image" content="https://cuantoestaeldolar.pe/img/og-1200x630.jpg" />
              <meta property="og:site_name" content="Cuánto está el dolar" />
            </>):(
            <>
              <title>
                Tipo de Cambio | Dólares a Soles | Precio del dólar en el Perú |
                compra y venta de dólares en Perú | Cuánto esta el dolár en el Perú
              </title>
              <meta name="description" content="Tipo de cambio, precio del dólar en soles para hoy - cotización del dólar en el Perú  - SUNAT - SBS y paralelo - Cambios Online - Cambiar dólares a soles"/>
              <meta name="apple-mobile-web-app-title" content="Precio Dólar"/>
              <meta property="og:title" content="Tipo de Cambio | Dólares a Soles | Precio del dólar en el Perú | compra y venta de dólares en Perú | Cuanto esta el dólar en el Perú" />
              <meta property="og:url" content="https://cuantoestaeldolar.pe/" />
              <meta property="og:image" content="https://cuantoestaeldolar.pe/img/og-1200x630.jpg" />
              <meta property="og:site_name" content="Cuánto está el dolar" />
              <meta property="og:description" content="Tipo de cambio, precio del dólar en soles para hoy - cotización del dólar en el Perú  - SUNAT - SBS y paralelo - Cambios Online - Cambiar dólares a soles" />
              <meta property="og:type" content="article" />
            </>)
          }
          <link rel="icon" href="/icons/favicon2x.png" />
        </NextHead>
        {/* <AdsSlot div='cuantoestaeldolar_pe_320x100_anchor_top_mobile' variant="top_mobile" className={`text-center block md:hidden`} /> */}
          {/* {!showAdsTop ? (
          <AdsSlot div='cuantoestaeldolar_pe_320x100_anchor_top_mobile' variant="top_mobile" className={`text-center block md:hidden`} />
          ) : (
            <AdsSlotTop className="md:hidden" />
          )} */}
          {device === 'mobile' && <AdsSlotTop className="md:hidden" />}
          <Navbar menu={menus} onlineExchangeHouses={ticker} />
        <div >
          {children}
        </div>
        <div className="clever-core-ads" />
      </main>
      <NewFooter/>
      <div id="script-container" />
      {/* {
        device === 'desktop' && 
          ( clever && <Script id="CleverCoreLoader57998" src="//scripts.cleverwebserver.com/7fddfd603c96f1bd7c1e6267fb461f44.js" type="text/javascript" data-target="" data-callback="put-your-callback-macro-here" strategy="afterInteractive" /> )
      }
      {
        device === 'mobile' && <Script id="CleverCoreLoader57998" src="//scripts.cleverwebserver.com/7fddfd603c96f1bd7c1e6267fb461f44.js" type="text/javascript" data-target="" data-callback="put-your-callback-macro-here" strategy="afterInteractive" />
      } */}
    </>
  )
}

export default Layout